import React from "react"
import tw from "twin.macro"
import Seo from "@components/seo"

// Components
import Layout from "@components/layout"
import Container from "@components/container"

// Assets
import logo from "@images/logos/xywav-logo.svg"

const SucThankYouPage = ({ location }) => {
  return (
    <Layout location={location} headerType="narcolepsy" footerType="narcolepsy">
      <Seo
        title="Thank You for Sharing Your Story | XYWAV®"
        location={location}
      />
      <section tw="relative bg-ghostWhite pt-[100px] pb-20 overflow-hidden md:(pt-40 pb-28) xl:py-40">
        <img
          src={logo}
          tw="hidden opacity-20 absolute w-[180px] top-9 -right-10 md:(block w-[630px] top-10 right-[-150px])"
          alt=""
        />
        <Container>
          <h1
            css={[
              tw`font-cocon font-bold text-[32px] leading-[1.15] mb-6 xl:(text-6xl mb-5)`,
            ]}
          >
            Thank you for
            <br /> sharing your story!
          </h1>
          <div tw="lg:(mb-6)">
            Keep an eye out for <span tw="md:hidden">more</span> information.
          </div>
        </Container>
      </section>
    </Layout>
  )
}

export default SucThankYouPage
